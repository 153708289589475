import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
  types,
  fetchOccupations,
  fetchOccupationsSuccess,
  fetchOccupationsFailure,
  fetchAlternativeInsurers,
  fetchAlternativeInsurersSuccess,
  fetchAlternativeInsurersFailure,
} from '@reducer/config/actions';

import { getOccupations } from '@services/api/occupation';
import { getAlternativeInsurers } from '@services/api/alternative-insurer';
import { retrieveRestrictedPeriods } from '@sagas/init';

import { getOptimizelyUserIdentity } from '@redux/reselectors/optimizely';
import { optimizelyClient } from '@services/clients/optimizely';
import { types as initActionTypes } from '../init';

export function* fetchOccupationSaga() {
  try {
    const data = yield call(getOccupations);
    yield put(fetchOccupationsSuccess(data));
  } catch (error) {
    yield put(fetchOccupationsFailure(error.message));
  }
}

export function* fetchAlternativeInsurersSaga() {
  try {
    const data = yield call(getAlternativeInsurers);
    const userIdentity = yield select(getOptimizelyUserIdentity);
    const reduceAltInsurerEnabled = optimizelyClient.isFeatureEnabled(
      'temp_reduce_alt_insurance',
      userIdentity,
      {
        user_identity: userIdentity,
      },
    );

    yield put(fetchAlternativeInsurersSuccess(data, reduceAltInsurerEnabled));
  } catch (error) {
    yield put(fetchAlternativeInsurersFailure(error.message));
  }
}

export function* fetchConfigRequestSaga() {
  yield put(fetchOccupations());
  yield put(fetchAlternativeInsurers());
}

export function* watchConfig() {
  yield takeEvery(types.CONFIG_OCCUPATION_REQUEST, fetchOccupationSaga);
  yield takeEvery(initActionTypes.INIT, fetchConfigRequestSaga);
  yield takeEvery(types.CONFIG_ALTERNATIVE_INSURERS_REQUEST, fetchAlternativeInsurersSaga);
  yield takeEvery(types.RESTRICTED_PERIODS_REQUEST, retrieveRestrictedPeriods);
}
