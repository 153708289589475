import { types, ConfigActions } from './actions';
import { ConfigState, Insurers, InsurerData } from './types';


// reducer
/* Fetchs the occupations list to fill the app */
export const initialState: ConfigState = {
  occupations: {
    student: [],
    other: [],
    employed: [],
  },
  alternativeInsurers: [],
  amplifyLoaded: false,
  occupationsError: null,
  alternativeInsurersError: null,
  occupationsLoading: false,
  alternativeInsurersLoading: false,
  restrictedPeriods: [],
  restrictedPeriodsError: null,
  optimizelyAttributes: {
    user_identity: '',
  },
};


const parseAlternativeInsurers = (data: InsurerData[], reduceAltInsurerEnabled: boolean): Insurers[] => {
  const insurers = data
    .filter((insurer) => insurer.label !== 'Other')
    .map((insurer) => ({
      name: insurer.label,
      value: insurer.label,
    }));

  insurers.push({ name: 'Other', value: 'Other' });

  if (reduceAltInsurerEnabled) {
    insurers.unshift({
      name: "There isn't a main insurance policy", value: 'false'
    });
  }
  return insurers;
};

export default function config(
  state: ConfigState = initialState,
  action = {} as ConfigActions,
): ConfigState {
  switch (action.type) {
    case types.CONFIG_OCCUPATION_REQUEST:
      return {
        ...state,
        occupationsLoading: true,
      };
    case types.CONFIG_OCCUPATION_SUCCESS:
      return {
        ...state,
        occupations: {
          ...state.occupations,
          employed: action.occupationsList.employed.sort(),
          student: action.occupationsList.student.sort(),
          other: action.occupationsList.other.sort(),
        },
        occupationsLoading: false,
        occupationsError: null,
      };
    case types.CONFIG_OCCUPATION_FAILURE:
      return {
        ...state,
        occupationsError: action.error,
        occupationsLoading: false,
      };
    case types.CONFIG_ALTERNATIVE_INSURERS_REQUEST:
      return {
        ...state,
        alternativeInsurersLoading: true,
      };
    case types.CONFIG_ALTERNATIVE_INSURERS_SUCCESS:
      return {
        ...state,
        alternativeInsurers: parseAlternativeInsurers(
          action.alternativeInsurers, action.reduceAltInsurerEnabled
        ),
        alternativeInsurersLoading: false,
        alternativeInsurersError: null,
      };
    case types.CONFIG_ALTERNATIVE_INSURERS_FAILURE:
      return {
        ...state,
        alternativeInsurersError: action.error,
        alternativeInsurersLoading: false,
      };
    case types.RESTRICTED_PERIODS_SUCCESS:
      return {
        ...state,
        restrictedPeriods: action.data,
      };
    case types.RESTRICTED_PERIODS_FAILURE:
      return {
        ...state,
        restrictedPeriodsError: action.error,
      };
    case types.AMPLIFY_LOADED:
      return {
        ...state,
        amplifyLoaded: true,
      };
    case types.SET_OPTIMIZELY_ATTRIBUTE:
      return {
        ...state,
        optimizelyAttributes: {
          ...state.optimizelyAttributes,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
}
