import React from 'react';
import FlatButton from '@atoms/buttons/FlatButton';
import Group from '@atoms/layout/form/Group';
import { FieldContainer } from '@rentecarlo/component-library';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import { Field } from 'redux-form';
import ReduxFieldValidation from '@services/formValidation';
import { errors } from '@molecules/inputs/ToggleButtonsField';
import { ComponentType, useComponentId } from '@hooks';
import analyticsClient from '@utils/analytics';

import { FlatButtonContainer, MainInsurerContainer } from '../assets/styles';
import HasInsuranceField from './HasInsuranceField';
import InsuranceProviders from './InsuranceProvidersField';

interface Props {
  alternativeInsurers: Array<{ name: string; value: string }>;
  otherInsuranceSelected: boolean;
  getInsurersName: string;
  hasInsurance: string;
  formErrors: Record<string, unknown>;
  isDriverOwner: boolean;
  productType: string;
  showErrors: boolean;
  error?: string;
  onSetHasInsurance: (value: string) => void;
  onSetTempcoverPersonalVehicle: () => void;
  isQuoteForMyself: boolean;
  stepTitle: string;
  reduceAltInsurers: boolean;
}

const mainInsuranceValid = (
  value: string,
  _allValues: Record<string, string>,
  props: Props,
): string | undefined => {
  const { isDriverOwner } = props;
  return !isDriverOwner && value === 'false' ? errors.ERROR_RIGHT : undefined;
};

const AIQuestionField: React.FC<Props> = (props) => {
  const creator = useComponentId();
  const {
    alternativeInsurers,
    otherInsuranceSelected,
    getInsurersName,
    hasInsurance,
    formErrors,
    isDriverOwner,
    productType,
    showErrors,
    error,
    onSetHasInsurance,
    onSetTempcoverPersonalVehicle,
    isQuoteForMyself,
    stepTitle,
    reduceAltInsurers,
  } = props;

  if (showErrors && mainInsuranceValid(hasInsurance, {}, props) === errors.ERROR_RIGHT) {
    analyticsClient.trackEvent('web_form_errors', {
      quote_step: stepTitle,
      error_trigger: 'onClick',
      error_details: [`alternativeInsurer: ${error}`],
    });
  }
  return (
    <Group id='aiQuestionField'>
      {!reduceAltInsurers ? (
        <>
          <SectionTitle id={creator(ComponentType.TITLE, 'AlternativeInsuranceQuestion')}>
            Is there already a main insurance policy in place for this vehicle?
          </SectionTitle>

          <FieldContainer
            id={formErrors && formErrors.mainInsurer ? 'mainInsurer' : 'hasInsurance'}
            showErrorContainer={showErrors}
            showError={showErrors}
            error={error}
          >
            <Field
              id={creator(ComponentType.FIELD, 'hasInsurance')}
              name='hasInsurance'
              component={HasInsuranceField}
              validate={[ReduxFieldValidation.required, mainInsuranceValid]}
              onChange={onSetHasInsurance}
              alternativeInsurers={alternativeInsurers}
              otherInsuranceSelected={otherInsuranceSelected}
              getInsurersName={getInsurersName}
              isDriverOwner={isDriverOwner}
              isQuoteForMyself={isQuoteForMyself}
            />
          </FieldContainer>
        </>
      ) : (
        <MainInsurerContainer>
          <FieldContainer
            id={formErrors && formErrors.mainInsurer ? 'mainInsurer' : 'hasInsurance'}
            showErrorContainer={showErrors}
            showError={showErrors}
            error={error}
          >
            <Field
              id={creator(ComponentType.FIELD, 'hasInsurance')}
              name='hasInsurance'
              validate={[ReduxFieldValidation.required]}
              onChange={onSetHasInsurance}
              component={InsuranceProviders}
              alternativeInsurers={alternativeInsurers}
              otherInsuranceSelected={otherInsuranceSelected}
              getInsurersName={getInsurersName}
              isDriverOwner={isDriverOwner}
              isQuoteForMyself={isQuoteForMyself}
            />
          </FieldContainer>
        </MainInsurerContainer>
      )}

      {['tc', 'newdriver'].includes(productType) && isDriverOwner && hasInsurance === 'false' && (
        <FlatButtonContainer>
          <Field
            id={creator(ComponentType.FIELD, 'tempCoverConfirmedRadioButton')}
            name='tempcoverConfirmedIndividualOwner'
            component={FlatButton}
            body={`I confirm ${
              isQuoteForMyself ? 'I am' : 'they are'
            } or will be the registered keeper of the car during the policy period`}
            onChange={onSetTempcoverPersonalVehicle}
            validate={[ReduxFieldValidation.isTrue]}
          />
        </FlatButtonContainer>
      )}
    </Group>
  );
};

export default AIQuestionField;
